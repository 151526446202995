export default {
    dev: false,
    apiUrl: "https://test.cantopia.fluhrer.games/api/", // cantopia backend server with trailing slash
    securityToken: "security-token", // cantopia backend server security token
    couch: {
        url: "https://test.cantopia.fluhrer.games/couchdb/", // couchdb instance with trailing slash
        username: "admin",
        password: "eSA54AYyi7jCDw29HGD",
    },
    version: '2.2.27'
}
